<template>
  <b-container class="my-5 py-5">
    <b-alert show variant="danger" id="errormessage">
      {{ errorMessage }}
    </b-alert>
  </b-container>
</template>
<script>
export default {
  computed: {
    errorMessage: () => {
      return 'Oops, there was a problem while processing your request.'
    }
  }
}
</script>
