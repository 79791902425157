import 'core-js/stable'
import Vue from 'vue'
import { FeaturePlugin } from './plugins/features'
import './plugins/bootstrap'
import './plugins/fontawesome'
import './filters'
import { createPinia, PiniaVuePlugin } from 'pinia'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

Vue.use(PiniaVuePlugin)
const pinia = createPinia()
Vue.use(pinia)
Vue.use(FeaturePlugin)

new Vue({
  router,
  pinia,
  render: h => h(App)
}).$mount('#app')
