import moment from 'moment'
import Vue from 'vue'

export const formatDate = (value, format = 'MMMM Do YYYY, H:mm') => {
  if (value) {
    return moment(String(value)).format(format)
  }
}

export const fromNow = (value) => {
  if (value) {
    return moment(String(value)).fromNow()
  }
}

export const niceDate = (value, format = 'MMMM Do YYYY, H:mm') => {
  if (value) {
    let date = moment(String(value))
    if (date.isAfter(moment().subtract(20, 'hours'))) {
      return date.fromNow()
    }
    return date.format(format)
  }
}

Vue.filter('formatDate', formatDate)
Vue.filter('fromNow', fromNow)
Vue.filter('niceDate', niceDate)
