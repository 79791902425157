<template>
  <div>
    <header class="pb-5">
      <b-container class="text-light text-center">
        <img :src="require('@/assets/2020/art-logo-white.png')" alt="Word art of Accelerate" class="mb-2 py-2" style="max-width: 90%; width: 300px">
        <h3>For developers. By developers.</h3>
      </b-container>
    </header>

    <b-jumbotron fluid class="bg-white mb-0">
      <b-container class="my-3">
        <b-row>
          <b-col lg="8" class="pr-4">
            <h2>// We will be back</h2>
            <p class="lead text-secondary py-3">
              Accelerate is the DevOps conference for all DevOps Engineers at KPN.
              During this 1-day conference we want to inspire you on subjects like public cloud, kubernetes, continuous deployment and other topics that enable engineers to deliver value faster.
            </p>
            <p class="lead text-secondary">
              We hope to plan a new Accelerate event in 2024 and meanwhile we will have other <router-link :to="{ name: 'on-the-road' }">smaller activities</router-link> as well.
            </p>
          </b-col>
          <b-col lg="4" class="d-none d-lg-block text-right">
            <img :src="require('@/assets/2020/art-logo-color.png')" style="max-width: 100%;" alt="Accelerate brand image">
          </b-col>
        </b-row>
      </b-container>
    </b-jumbotron>

    <bottom-about></bottom-about>
  </div>
</template>

<script>
import BottomAbout from '@/components/BottomAbout.vue'
export default {
  components: {
    'bottom-about': BottomAbout
  },
  data () {
    return {}
  }
}
</script>
