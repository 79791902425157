import Vue from 'vue'
import Router from 'vue-router'
import GenericRoutes from './views/generic/router'
import OnTheRoadRoutes from './views/ontheroad/router'
import { getRuntimeConfig } from './config'

Vue.use(Router)

const routes = [].concat(GenericRoutes, OnTheRoadRoutes)
const env = getRuntimeConfig()

export default new Router({
  mode: 'history',
  base: env.BASE_URL,
  routes: routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  }
})
