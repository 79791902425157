<template>
  <div>
    <navbar/>
    <div>
      <router-view/>
    </div>
    <d-footer/>
  </div>
</template>

<script>
import Footer from '@/components/PageFooter.vue'
import Navbar from '@/components/TopNavbar.vue'
import '@/assets/2020/style.css'

export default {
  components: {
    navbar: Navbar,
    'd-footer': Footer
  }
}
</script>
