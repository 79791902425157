import AboutPage from './AboutPage.vue'
import ErrorPage from './ErrorPage.vue'
import GenericView from './GenericView.vue'
import HomePage from './HomePage.vue'

export default [
  {
    path: '/',
    component: GenericView,
    children: [
      { name: 'home', path: '/', component: HomePage },
      { name: 'error', path: 'error/', component: ErrorPage },
      { name: 'about', path: 'about/', component: AboutPage }
    ]
  }
]
