import { useFeatureStore } from '@/stores/feature'

export const FeaturePlugin = {

  install (Vue) {
    const store = useFeatureStore()
    Vue.prototype.$featureFlag = {
      enabled: store.enabled,
      value: store.value
    }
    store.getFlags()
  }

}
