import { getRuntimeConfig } from '@/config'
import { defineStore } from 'pinia'

export const useFeatureStore = defineStore('feature', {
  state: () => ({
    flags: {}
  }),
  getters: {
    enabled: state =>  feature => !!state.flags[feature],
    value: state => feature => state.flags[feature]
  },
  actions: {
    getFlags () {
      const flags = {}
      const env = getRuntimeConfig()
      Object.keys(env).filter(key => key.startsWith('VUE_APP_FEATURE_')).forEach(key => {
        const flag_name = key.replace(/^VUE_APP_FEATURE_/, '')
        flags[flag_name] = env[key]
      })
      this.flags = flags
    }
  }
})
