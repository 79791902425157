<template>
  <b-jumbotron fluid class="bg-semi-transparent my-0 text-white">
    <b-container class="my-3">
      <b-row>
        <b-col lg="2" class="d-none d-lg-block">
          <img :src="require('@/assets/2020/icons/info-white.png')" style="max-width: 70%" alt="Info icon">
        </b-col>
        <b-col lg="10" offset-lg="0" sm="11" offset-sm="1">
          <h2>// About</h2>
          <p class="lead text-light">
            Accelerate is <em>the</em> KPN DevOps and engineering conference organised by developers for developers.
            This event is targeted at all software engineers at KPN.
          </p>
          <p class="lead text-light">
            Accelerate is an initiative of TechBase, DevX &amp; D-Nitro organised together with other KPN teams.
          </p>
        </b-col>
      </b-row>
    </b-container>
  </b-jumbotron>
</template>

<script>
export default {
  data () {
    return {
      authenticated: false,
    }
  }
}
</script>
