import GenericView from '../generic/GenericView.vue'
import HomePage from './HomePage.vue'

export default [
  {
    path: '/techbase-on-the-road/',
    component: GenericView,
    children: [
      { name: 'on-the-road', path: '/', component: HomePage },
    ]
  }
]
